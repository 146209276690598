<template>
    <div class="main-content">
        <div class="main-wrap">
            <SingleReport id="studentInfo" ref="studentInfo" :studentInfo="studentInfo" v-if="isInfo"></SingleReport>
            <div class="btn-content">
                <el-button size="medium" class="btn" @click="returnList">
                    <i class="iconfont">&#xe72e;</i>
                    <span style="margin-top:8px">返回</span>
                </el-button>
                <el-button size="medium" class="btn-blue" @click="prints">
                    <i class="iconfont">&#xe621;</i>
                    <span style="margin-top:8px">打印</span>
                </el-button>
                <el-button size="medium" class="btn-g" @click="nextStudent">
                    <span>下一位</span>
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Print from '@/utils/vue-print-nb/src/printarea.js'
    import SingleReport from "../../../components/SingleReport";
    import {examSeeStuReport, reportManagementStuList} from "@/utils/apis"

    export default {
        name: "TeacherReport",
        components: {
            SingleReport
        },
        created() {
            this.getStuReport();
            this.getList();
        },
        data() {
            return {
                exam_id: this.$route.query.id,
                student_id: '',
                studentInfo: [],
                isInfo: false,
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
                studentList: []
            }
        },
        watch:{
            $route(route){
                this.getStuReport();
                this.getList();
            }
        },
        methods: {
            getStuReport() {
                this.student_id = this.$route.query.stu_id;
                this.studentInfo = [];
                this.isInfo = false;
                let param = {
                    exam_id: this.exam_id,
                    student_id: this.student_id
                }
                examSeeStuReport(param).then(res => {
                    this.studentInfo = res.data;
                    this.isInfo = true;
                }).catch(err => {
                    console.error(err)
                })
            },
            getList() {
                let param = {
                    exam_id: this.exam_id,
                    page: this.currentPageNum,
                    paging: 1,
                    pageSize: this.eachPageNum
                }
                reportManagementStuList(param).then(res => {
                    this.studentList = res.data.data;
                    this.total = res.data.total
                }).catch(err => {
                    console.error(err)
                })
            },
            returnList() {
                this.$router.push({
                    path: '/evaluationCenter/reportManagement/detail',
                    query: {
                        id: this.$route.query.id
                    }
                });
            },
            prints() {
                setTimeout(() => {
                    new Print({
                        ids: "#studentInfo", // * 局部打印必传入id
                        endCallback() {
                            // 调用打印之后的回调事件
                            console.log('success');
                        }
                    });
                }, 500);
            },
            nextStudent() {
                let nextStu = '';
                let len = this.studentList.length;
                this.studentList.forEach((item, index) => {
                    if (item.student_id == this.student_id) {
                        if (index == len - 1) {
                            nextStu = this.studentList[0].student_id
                        } else {
                            nextStu = this.studentList[index + 1].student_id
                        }
                    }
                })
                this.$router.push({
                    query: {
                        id: this.exam_id,
                        stu_id: nextStu
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-content {
        position: fixed;
        top: 60px;
        bottom: 0;
        width: 100%;
        background: #F5F7F6;

        .main-wrap {
            display: flex;
            padding: 20px 0;
            height: 100%;
            margin: 0 auto;
            overflow: auto;
            justify-content: center;
            box-sizing: border-box;

            .btn-content {
                display: flex;
                flex-direction: column;
                align-self: flex-end;
                padding-left: 20px;

                .btn {
                    width: 64px;
                    height: 64px;
                    background: #fff;
                    border: 1px solid #D2D2D2;
                    color: #A0A0A0;
                    border-radius: 4px;
                    margin-left: 10px;

                    span {
                        display: flex;
                        flex-direction: column;

                        i {
                            height: 16px;
                            line-height: 0;
                            font-size: 20px;
                            color: #A0A0A0;
                        }
                    }
                }

                .btn-blue {
                    width: 64px;
                    height: 64px;
                    border: 1px solid #2461EF;
                    color: #2461EF;
                    border-radius: 4px;
                    margin: 30px 10px;

                    span {
                        display: flex;
                        flex-direction: column;

                        i {
                            height: 16px;
                            line-height: 0;
                            font-size: 20px;
                        }
                    }
                }


                .btn-g {
                    width: 64px;
                    height: 64px;
                    border: 1px solid #2DC079;
                    color: #2DC079;
                    border-radius: 4px;
                    margin-top: 13px;

                    span {
                        margin-left: -8px;
                    }
                }
            }
        }
    }
</style>